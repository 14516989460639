<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 头部注释
 * @Date: 2020-12-27 14:37:22
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-12-27 15:06:24
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/Browsinghistory.vue
-->
<template>
  <div class="user_content">
    <div class="list">
      <div v-for="(item,index) in list" :key="index" class="list_item">
        <div><img :src="item.imgUrl" alt=""></div>
        <div>
          <div><span>{{ item.type | state }}</span> {{ item.goodsName }}</div>
          <div><span>¥{{ item.current_price }}</span> <span>¥{{ item.original_price }}</span></div>
          <div>热销{{ item.count }}件</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Icon, Toast } from 'vant'

Vue.use(Icon).use(Toast)
import {
  productBrowsingList
} from '@/services/userApi'

export default {
  name: 'BrowsingHistory',
  filters: {
    state(v) {
      const map = {
        0: '自营',
        1: '京东',
        2: '拼多多',
        3: '秒杀'
      }
      return map[v]
    }
  },
  data() {
    return {
      mid: '',
      page: 1,
      pageSize: 10,
      list: []
    }
  },
  created() {
    this.mid = localStorage.getItem('uid')
    this.getInfo()
  },
  methods: {
    getInfo() {
      const params = {
        mid: this.mid,
        page: this.page,
        pageSize: this.pageSize
      }
      productBrowsingList(params).then(res => {
        if (Number(res.code) === 200) {
          this.list = res.data
        } else {
          Toast(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .list {
    background: #fff;
    margin-top: 10px;
    padding: 13px;

    .list_item {
      border-bottom: 1px solid #999;
    }
  }
}
</style>
